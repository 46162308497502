@use '@/styles/utils/mixins.scss' as *;

.faqsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    @include tab() {
      flex-direction: column;
    }
  }
  .sectionDescription {
    max-width: 29rem;
  }
  .faqCategoryCardsSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @include tab-des() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    .faqCategoryCard {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      height: fit-content;
      padding: 2.5rem 1.875rem;
      border: 0.063rem solid $primaryAlt;
      border-radius: 1.25rem;

      :global {
        .ant-collapse,
        .ant-collapse-ghost {
          display: flex;
          flex-direction: column;
          gap: 1.25rem;
        }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          padding: 0;
          color: $dark;
          font-weight: bold;
          line-height: 1;
          cursor: pointer;
          transition:
            all 0.3s,
            visibility 0s;
        }
        .ant-collapse-ghost
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          padding-block: 0rem;
          padding: 0;
          padding-top: 1.25rem;
        }

        .titleMd {
          @include mob() {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
